import "./styles.css";
import { useState } from "react";

const data = {
  annotations: [
    {
      label: "Normal Light",
      name: "normal",
      src:
        "https://publications.artic.edu/whistlerart/sites/publications.artic.edu.whistlerart/files/svg_assets/Whistler2_1912_141_NRM_anno-9.svg"
    },
    {
      label: "Infrared Reflectrogram (1.5–1.7 µm)",
      name: "infrared",
      src:
        "https://publications.artic.edu/whistlerart/sites/publications.artic.edu.whistlerart/files/svg_assets/Whistler2_1912_141_IRG_anno-2.svg"
    }
  ],
  images: [
    {
      label: "Normal Light",
      src: "https://loremflickr.com/675/875/art?random=1"
    },
    { label: "X-Ray", src: "https://loremflickr.com/675/875/art?random=2" },
    {
      label: "Infrared Reflectogram (1.0-1.1 µm)",
      src: "https://loremflickr.com/675/875/art?random=3"
    },
    {
      label: "Infrared Reflectrogram (1.5–1.7 µm)",
      src: "https://loremflickr.com/675/875/art?random=4"
    },
    {
      label: "Ultraviolet",
      src: "https://loremflickr.com/675/875/art?random=5"
    }
  ]
};

const initialValues = {
  annotations: {
    normal: false,
    infrared: false
  },
  zoom: 0,
  layers: {
    base: "Normal Light",
    overlay: "X-Ray"
  },
  slider: 50
};

export default function App() {
  const [values, setValues] = useState(initialValues);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);
  };

  return (
    <div className="app">
      <div id="image-viewer" className="image-viewer">
        <div id="view-finder" className="view-finder"></div>
        <ul id="images" className="images">
          {data.images.map((image) => {
            const base = values.layers.base === image.label;
            const overlay = values.layers.overlay === image.label;
            const classes = `item${base ? " base" : ""}${
              overlay ? " overlay" : ""
            }`;

            let opacity = 0;
            if (base) opacity = 1;
            if (overlay) opacity = values.slider / 100;

            return (
              <li key={image.src} className={classes}>
                <div className="image-wrapper">
                  <img
                    src={image.src}
                    alt={image.alt}
                    style={{ opacity: `${opacity}` }}
                  />
                </div>
              </li>
            );
          })}

          {data.annotations.map((image) => {
            const active = Object.keys(values.annotations).filter(
              (annotation) => {
                return (
                  annotation === image.name &&
                  values.annotations[annotation] === true
                );
              }
            );
            const classes = `item${active.length ? " annotation" : ""}`;
            const opacity = active.length ? "1" : "0";

            return (
              <li key={image.src} className={classes}>
                <div className="image-wrapper">
                  <img
                    src={image.src}
                    alt={image.alt}
                    style={{ opacity: `${opacity}` }}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div id="user-interface" className="user-interface">
        <form id="form" action="#" onSubmit={handleSubmit}>
          <ul>
            <li className="annotations">
              <fieldset>
                <legend>Annotations</legend>

                <label htmlFor="annotations-normal">
                  <input
                    type="checkbox"
                    id="annotations-normal"
                    name="annotations-normal"
                    value={values.annotations.normal}
                    onChange={() =>
                      setValues({
                        ...values,
                        annotations: {
                          ...values.annotations,
                          normal: !values.annotations.normal
                        }
                      })
                    }
                  />
                  Normal Light
                </label>

                <label htmlFor="annotations-infrared">
                  <input
                    type="checkbox"
                    id="annotations-infrared"
                    name="annotations-infrared"
                    value={values.annotations.infrared}
                    onChange={() =>
                      setValues({
                        ...values,
                        annotations: {
                          ...values.annotations,
                          infrared: !values.annotations.infrared
                        }
                      })
                    }
                  />
                  Infrared Reflectrogram (1.5–1.7 µm)
                </label>
              </fieldset>
            </li>

            <li className="zoom">
              <button id="zoom-in" type="button">
                +
              </button>
              <div>
                <label htmlFor="zoom">Zoom Level</label>
                <input
                  type="number"
                  name="zoom"
                  id="zoom"
                  value={values.zoom}
                  onChange={handleChange}
                />
              </div>
              <button id="zoom-out" type="button">
                -
              </button>
            </li>

            <li className="layers">
              <fieldset>
                <legend>Layers</legend>

                <label htmlFor="layer-base">Layer 1</label>
                <select
                  id="layer-base"
                  name="layer-base"
                  value={values.layers.base}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      layers: { ...values.layers, base: e.target.value }
                    })
                  }
                >
                  <option value="Normal Light">Normal Light</option>
                  <option value="X-Ray">X-Ray</option>
                  <option value="Infrared Reflectogram (1.0-1.1 µm)">
                    Infrared Reflectogram (1.0-1.1 µm)
                  </option>
                  <option value="Infrared Reflectrogram (1.5–1.7 µm)">
                    Infrared Reflectrogram (1.5–1.7 µm)
                  </option>
                  <option value="Ultraviolet">Ultraviolet</option>
                </select>

                <label htmlFor="layer-overlay">Layer 1</label>
                <select
                  id="layer-overlay"
                  name="layer-overlay"
                  value={values.layers.overlay}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      layers: { ...values.layers, overlay: e.target.value }
                    })
                  }
                >
                  <option value="Normal Light">Normal Light</option>
                  <option value="X-Ray">X-Ray</option>
                  <option value="Infrared Reflectogram (1.0-1.1 µm)">
                    Infrared Reflectogram (1.0-1.1 µm)
                  </option>
                  <option value="Infrared Reflectrogram (1.5–1.7 µm)">
                    Infrared Reflectrogram (1.5–1.7 µm)
                  </option>
                  <option value="Ultraviolet">Ultraviolet</option>
                </select>

                <label htmlFor="slider">Layers</label>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={values.slider}
                  id="slider"
                  name="slider"
                  onChange={handleChange}
                />
              </fieldset>
            </li>

            <li className="reset">
              <button id="reset" type="button">
                Reset
              </button>
            </li>

            <li className="expand">
              <button id="expand" type="button">
                Expand
              </button>
            </li>

            <li className="submit">
              <button id="submit" type="submit">
                Submit
              </button>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
}
